import React, { useEffect } from "react";
import HomepageHeader from "./HeaderComponent/HomepageHeader";
import classes from "./Homepage.module.css";
import HomepageWhy from "./HeaderComponent/HomepageWhy";
import HomepageProduct from "./ProductComponent/HomepageProduct";
import HomepageFeature from "./FeatureComponent/HomepageFeature";
import HomepageScrollVideo from "./ScrollVideoComponent/HomepageScrollVideo";
import HomepageTeam from "./TeamComponent/HomepageTeam";

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.homepage}>
      <HomepageHeader />
      <HomepageWhy />
      <HomepageProduct />
      <HomepageFeature />
      <HomepageScrollVideo />
      <HomepageTeam />
    </div>
  );
};

export default Homepage;
