import React from "react";
import classes from "../Homepage.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SliderItem = ({ detail }) => {
  const socialConnects = detail.socials.map((social) => (
    <a href={social.redirectUrl} target="_blank" rel="noreferrer">
      {social.icon}
    </a>
  ));
  return (
    <div className={classes.slider_item}>
      <LazyLoadImage
        src={detail.imgUrl}
        alt={`${detail.name} image`}
        className={classes.slider_item_img}
      />
      <div className={classes.slider_content}>
        <h4 className={classes.slider_content__name}>{detail.name}</h4>
        <h5 className={classes.slider_content_title}>{detail.title}</h5>
        <div className={classes.slider_social_connects}>{socialConnects}</div>
        <p className={classes.slider_content_description}>
          {detail.description}
        </p>
      </div>
    </div>
  );
};

export default SliderItem;
