import React, { useEffect } from "react";
import classes from "../Homepage.module.css";
import Fade from "react-reveal/Fade";
import { LazyLoadImage } from "react-lazy-load-image-component";

const FeatureDetails = ({ feature }) => {
  // For giving delay for fade animation for each component
  let delayTime;
  useEffect(() => {
    if (window.innerWidth > 500) {
      // If the inner width is less than 500; delay is reduced
      delayTime = feature.id < 2 ? feature.id * 1500 : feature.id * 900;
    } else {
      delayTime = 1800;
    }
  }, []);

  return (
    <div className={classes.feature_detail}>
      <Fade right duration={delayTime}>
        <LazyLoadImage
          src={feature.icon}
          alt={`${feature.title} image`}
          className={classes.feature_detail_img}
        />
        <h6 className={classes.feature_detail_header}>{feature.title}</h6>
        <p className={classes.feature_detail_description}>
          {feature.description}
        </p>
      </Fade>
    </div>
  );
};

export default FeatureDetails;
