import React from "react";
import classes from "./Signup.module.css";
import { useNavigate } from "react-router-dom";

const SignupHero = () => {
  const navigate = useNavigate();
  return (
    <>
      <h2 className={classes.signup_header}>CREATE ACCOUNT</h2>
      <p className={classes.login_redirect}>
        Already have an account? &nbsp;
        <span className={classes.blue_text} onClick={() => navigate("/login")}>
          Sign in
        </span>
      </p>
    </>
  );
};

export default SignupHero;
