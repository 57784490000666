import React from "react";
import classes from "../SignupPage/Signup.module.css";
import { useNavigate } from "react-router-dom";

const LoginHero = () => {
  const navigate = useNavigate();
  return (
    <>
      <h2 className={classes.signup_header}>CREATE ACCOUNT</h2>
      <p className={classes.login_redirect}>
        Doesn't have an account? &nbsp;
        <span className={classes.blue_text} onClick={() => navigate("/signup")}>
          Signup
        </span>
      </p>
    </>
  );
};

export default LoginHero;
