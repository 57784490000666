import React, { useEffect, useRef } from "react";
import Button from "../../components/SignupLogin/Button";
import classes from "./Signup.module.css";
import { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FontAwesome from "./FontAwesome";
import Input from "../../components/SignupLogin/Input";
import { NAME_REGEX, PWD_REGEX, EMAIL_REGEX } from "./REGEX_VALUES";

const SignupForm = () => {
  const nameRef = useRef();
  const [fname, setFname] = useState("");
  const [validFname, setValidFname] = useState(false);

  const [lname, setLname] = useState("");
  const [validLname, setValidLname] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatchPwd, setValidMatchPwd] = useState(false);

  const [btnDisable, setBtnDisable] = useState(true);

  //   Checking if all inputs are valid
  const validInpCheck = () => {
    if (validFname && validLname && validEmail && validPwd && validMatchPwd) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  };

  //   At the first load, username input is focussed, also disabling submit btn
  useEffect(() => {
    nameRef.current.focus();
  }, []);

  //   Validating first name when the new input is given
  useEffect(() => {
    const result = NAME_REGEX.test(fname);
    setValidFname(result);
  }, [fname]);

  //   Validating last name when the new input is given
  useEffect(() => {
    const result = NAME_REGEX.test(lname);
    setValidLname(result);
  }, [lname]);

  //   Validating email when the new input is given
  useEffect(() => {
    const result = EMAIL_REGEX.test(email);

    setValidEmail(result);
  }, [email]);

  //   Validating password when the new input is given
  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd && result;
    setValidMatchPwd(match);
  }, [pwd, matchPwd]);

  useEffect(() => {
    validInpCheck();
  }, [validFname, validLname, validEmail, validPwd, validMatchPwd]);

  //   Form Submission Handler

  const formSubmit = (e) => {
    e.preventDefault();
    // Rechecking if all the values are added; to prevent jHack
    if (
      !validFname &&
      !validLname &&
      !validEmail &&
      !validPwd &&
      !validMatchPwd
    ) {
      toast.error("Fill all the required fields");
      return;
    }
    console.log({
      firstName: fname,
      lastName: lname,
      email,
      password: pwd,
    });
    toast("User created");
  };
  return (
    <form onSubmit={formSubmit}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className={classes.name_input__container}>
        <Input
          matchVal={fname}
          changeSetVal={(val) => setFname(val)}
          placeholderText="First Name"
          validVal={validFname}
          curRef={nameRef}
        />
        <FontAwesome validValue={validFname} value={fname} />

        <Input
          matchVal={lname}
          changeSetVal={(val) => setLname(val)}
          placeholderText="Last Name"
          validVal={validLname}
        />

        <FontAwesome validValue={validLname} value={lname} />
      </div>
      <div className={classes.input_container}>
        <Input
          matchVal={email}
          changeSetVal={(val) => setEmail(val)}
          placeholderText="Email Address"
          validVal={validEmail}
        />
        <FontAwesome validValue={validEmail} value={email} />
      </div>
      <div className={classes.input_container}>
        <Input
          matchVal={pwd}
          changeSetVal={(val) => setPwd(val)}
          placeholderText="Password"
          validVal={validPwd}
        />
        <FontAwesome validValue={validPwd} value={pwd} />
      </div>
      <p className={classes.pwdInfo}>
        *Password must be 6 length or more of alphanumeric characters
      </p>
      <div className={classes.input_container}>
        <Input
          matchVal={matchPwd}
          changeSetVal={(val) => setMatchPwd(val)}
          placeholderText="Confirm Password"
          validVal={validMatchPwd}
        />
        <FontAwesome validValue={validMatchPwd} value={matchPwd} />
      </div>
      <Button
        type={{
          description: "Sign up with Email",
          color: "#019BFE",
          disabled: btnDisable,
        }}
      />
    </form>
  );
};

export default SignupForm;
