import React from "react";
import Fade from "react-reveal/Fade";
import classes from "../Homepage.module.css";

const HomepageWhy = () => {
  return (
    <div className={classes.homepage_why__container}>
      <Fade top>
        <h5 className={classes.homepage_why__header}>WHY US</h5>
      </Fade>
      <Fade bottom>
        <p className={classes.homepage_why__description}>
          We use cutting-edge technologies in robotics, artificial intelligence
          and deep learning to make products which are economically viable for
          your market.
        </p>
      </Fade>
    </div>
  );
};

export default HomepageWhy;
