import React from "react";
import classes from "../Homepage.module.css";
import ProductDetail from "./ProductDetail";
import Fade from "react-reveal/Fade";
import agriRobotsImg from "../../../images/homepage_header.png";
import logisticsRobotsImg from "../../../images/logisticsRobots.png";
import serviceRobotsImg from "../../../images/serviceRobots.png";

const PRODUCT_CONTENT = [
  {
    id: 1,
    title: "AGRI",
    descriptions: [
      "Autonomous and remotely operated mobile robots",
      "Seperate robots for indoor and outdoor farms",
      "Applications include weed removal, spraying, load carrying and yield estimation",
      "Tailored solution for your farms",
    ],
    color: "green",
    redirectLink: "/agri-robots",
    img: agriRobotsImg,
  },
  {
    id: 2,
    title: "LOGISTICS",
    descriptions: [
      "Autonomous and tailored solution for your warehouse",
      "Increased efficiency",
      "Reduced labour cost",
      "Improved safety and better accuracy in material handling",
    ],
    color: "red",
    redirectLink: "/logistic-robots",
    img: logisticsRobotsImg,
  },
  {
    id: 3,
    title: "SERVICE",
    descriptions: [
      "Autonomous and tailored solution for your hospitals, restaurants, etc.",
      "Easy to use",
      "Medical graded",
      "Reduced labour cost and improve efficiency",
    ],
    redirectLink: "service-robots",
    color: "blue",
    img: serviceRobotsImg,
  },
];

const HomepageProduct = () => {
  const productsLists = PRODUCT_CONTENT.map((product) => (
    <ProductDetail product={product} />
  ));
  return (
    <div className={classes.homepage_product__container}>
      <Fade right>
        <h3
          align="center"
          id="product_component"
          className={classes.product_header}
        >
          PRODUCTS
        </h3>
      </Fade>

      {productsLists}
    </div>
  );
};

export default HomepageProduct;
