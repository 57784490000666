import React, { useEffect } from "react";
import Contact from "../../components/Contact.jsx";
import CommingSoon from "./CommingSoon.jsx";
import MetaDecorator from "../../util/MetaDecorator";

const Logisticspage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ backgroundColor: "black", color: "white" }}>
      <MetaDecorator
        title="Autogenbots | Logistics Robots"
        description="Autogenbots provide logistics solution to warehouse, hydroponics, green houses, factories, etc."
      />
      <CommingSoon item="Logistics" />
      <Contact />
    </div>
  );
};

export default Logisticspage;
