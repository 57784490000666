import React from "react";

import classes from "../SignupPage/Signup.module.css";
import Button from "../../components/SignupLogin/Button";
import LoginForm from "./LoginForm";
import LoginHero from "./LoginHero";

import { AiOutlineGoogle, AiFillFacebook } from "react-icons/ai";

const Login = () => {
  const googleClickHandler = () => {
    return;
  };
  const fbClickHandler = () => {
    return;
  };
  return (
    <div className={classes.signup_layout}>
      <LoginHero />
      <Button
        type={{
          description: "Login with Google",
          color: "#019BFE",
          onClick: googleClickHandler,
          icon: <AiOutlineGoogle className={classes.icon} />,
        }}
      />
      <Button
        type={{
          description: "Login with Facebook",
          color: "#3B5998",
          onClick: fbClickHandler,
          icon: <AiFillFacebook className={classes.icon} />,
        }}
      />
      <span className={classes.or}>or</span>
      <LoginForm />
    </div>
  );
};

export default Login;
