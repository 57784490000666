import React, { useRef } from "react";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
init("user_mNF2hAyVwCH02WN5e7WQV");

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    // Sending message from contact page
    emailjs
      .sendForm(
        "service_08qhiud",
        "template_kxor1li",
        form.current,
        "user_mNF2hAyVwCH02WN5e7WQV"
      )
      .then(
        (result) => {
          toast.success("Email Send", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            newestOnTop: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        (error) => {
          toast.warn("Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            newestOnTop: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
    e.target.reset();
  };
  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row">
        <div className="col-10 mx-auto">
          <div class="row g-0  rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div class="col p-4 d-flex flex-column position-static">
              <div className="=my-5">
                <h1 className="text-center">Contact Us</h1>
              </div>

              <div className="container contact_div">
                <div className="row">
                  <div className="col-md-6 col-10 mx-auto">
                    <form ref={form} onSubmit={sendEmail}>
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          FullName
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          name="user_name"
                          //value={data.fullname}
                          onChange={InputEvent}
                          placeholder="Enter your name"
                        />
                        <label for="exampleInputEmail1" class="form-label">
                          Phone
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="exampleInputEmail1"
                          name="user_phone"
                          onChange={InputEvent}
                          placeholder="Phone"
                        />
                        <label for="exampleInputEmail1" class="form-label">
                          Email address
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          name="user_email"
                          onChange={InputEvent}
                          placeholder="email"
                        />
                      </div>
                      <div class="mb-3">
                        <label
                          for="exampleFormControlTextarea1"
                          class="form-label"
                        >
                          Message
                        </label>
                        <textarea
                          type="text"
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          name="message"
                          onChange={InputEvent}
                          placeholder="Message"
                        ></textarea>
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          value="Send"
                          class="btn btn-outline-primary"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
