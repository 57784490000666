import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/LegacyCode/Home";
// import './App.css'
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Service from "./pages/LegacyCode/Service";
import Contact from "./pages/LegacyCode/Contact";

import Footer from "./components/footer/Footer";
import { useEffect, useState } from "react";
import Homepage from "./pages/Homepage/Homepage";

import Lottie from "react-lottie";
import animationData from "./util/robot_loader.json";
import Agripage from "./pages/Agripage/Agripage.jsx";
import Logisticspage from "./pages/Logisticspage/Logisticspage";
import Servicepage from "./pages/Servicepage/Servicepage";
import AboutusPage from "./pages/AboutusPage/AboutusPage";
import Signup from "./pages/SignupPage/Signup";
import Login from "./pages/LoginPage/Login";

function App() {
  // For loader values
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [isLoader, setIsLoader] = useState(true);
  const [loaderDelay, setLoaderDelay] = useState(false);
  useEffect(() => {
    // Setting Loading
    // Setting Load for 2.8 seconds
    setTimeout(() => setLoaderDelay(true), 300);
    setTimeout(() => {
      setIsLoader(false);
    }, 2800);
  }, []);
  return (
    <>
      <Router>
        <Navbar />
        {isLoader && (
          <div className="logo_section">
            {loaderDelay && <Lottie options={defaultOptions} />}
          </div>
        )}

        {!isLoader && (
          <Routes>
            <Route path="/" exact element={<Homepage />} />
            <Route path="/agri-robots" exact element={<Agripage />} />
            <Route path="/logistic-robots" exact element={<Logisticspage />} />
            <Route path="/service-robots" exact element={<Servicepage />} />
            <Route path="/about" exact element={<AboutusPage />} />

            <Route path="/home" exact element={<Home />} />
            <Route path="/service" exact element={<Service />} />

            <Route path="/contact" exact element={<Contact />} />
            <Route path="/signup" exact element={<Signup />} />
            <Route path="/login" exact element={<Login />} />
          </Routes>
        )}
      </Router>
      <Footer />
    </>
  );
}

export default App;
