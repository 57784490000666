import React, { useEffect, useState } from "react";
import videoFile from "../../../videos/LinkedIn.mp4";
import classes from "../Homepage.module.css";
import { Controller, Scene } from "react-scrollmagic";

const HomepageScrollVideo = () => {
  // Scroll position state
  const [scrollY, setScrollY] = useState(0);
  // Playing time state
  const [playTime, setPlayTime] = useState(0);

  function logit() {
    // Scroll value is set in scrollY
    setScrollY(window.pageYOffset);
    // ScrollY value is used to determine the playback position of the video
    setPlayTime((scrollY - 3000) / 800);
  }

  useEffect(() => {
    const video = document.getElementById("video_src");
    // Whenever playtime value changes , video src current time is changed
    video.currentTime = playTime;
  }, [playTime]);

  useEffect(() => {
    // Watching Scroll
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });
  return (
    <div id="video_start" className={classes.video_start}>
      <Controller>
        <Scene
          duration={4900}
          pin="#video_start"
          triggerElement="#video_start"
          triggerHook="0"
        >
          <video
            id="video_src"
            className={classes.video_src}
            src={videoFile}
            currentTime={10}
          ></video>
        </Scene>
      </Controller>
    </div>
  );
};

export default HomepageScrollVideo;
