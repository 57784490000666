import React, { useEffect } from "react";
import Contact from "../../components/Contact.jsx";
import CommingSoon from "../Logisticspage/CommingSoon.jsx";
import MetaDecorator from "../../util/MetaDecorator";

const Servicepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ backgroundColor: "black", color: "white" }}>
      <MetaDecorator
        title="Autogenbots | Service Robots"
        description="Autogenbots provide service robots to hospitals, restaurants, etc. Our product is medical graded and safe for hospital and restaurant uses."
      />
      <CommingSoon item="Service" />
      <Contact />
    </div>
  );
};

export default Servicepage;
