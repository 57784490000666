import React, { useEffect } from "react";
import Home from "../LegacyCode/Home";
import MetaDecorator from "../../util/MetaDecorator";

const Agripage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ backgroundColor: "black", color: "white" }}>
      <MetaDecorator
        title="Autogenbots | Agri-Robots"
        description="Autogenbots provide service Agri-Robots to orchid farms, wineyards and many other farm areas. Our product can be used for spraying, weed removal, berry counting, load carrying, etc."
      />
      <Home />
    </div>
  );
};

export default Agripage;
