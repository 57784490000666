import React from "react";
import Button from "../../components/SignupLogin/Button";
import SignupForm from "./SignupForm";
import SignupHero from "./SignupHero";
import classes from "./Signup.module.css";

import { AiOutlineGoogle, AiFillFacebook } from "react-icons/ai";

const Signup = () => {
  const googleClickHandler = () => {
    return;
  };
  const fbClickHandler = () => {
    return;
  };
  return (
    <div className={classes.signup_layout}>
      <SignupHero />
      <Button
        type={{
          description: "Sign up with Google",
          color: "#019BFE",
          onClick: googleClickHandler,
          icon: <AiOutlineGoogle className={classes.icon} />,
        }}
      />
      <Button
        type={{
          description: "Sign up with Facebook",
          color: "#3B5998",
          onClick: fbClickHandler,
          icon: <AiFillFacebook className={classes.icon} />,
        }}
      />
      <span className={classes.or}>or</span>
      <SignupForm />
    </div>
  );
};

export default Signup;
