import React from "react";
import classes from "../Homepage.module.css";
import homepageHeaderImg from "../../../images/homepage_header.png";
import Fade from "react-reveal/Fade";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HomepageHeader = () => {
  // Scroll To functionality handler
  const discoverBtnHandler = () => {
    const productComponent = document.getElementById("product_component");
    window.scrollTo({
      top: productComponent.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <div className={classes.homepage_header}>
      <Fade left>
        <div className={classes.homepage_header__info}>
          <h3 className={classes.homepage_header__heading}>
            ROBOTIC MOBILITY DELIVERED
          </h3>
          <p className={classes.homepage_header__description}>
            An emerging startup that seeks to provide mobility solutions for
            industries like agriculture, logistics, service sectors, etc by
            providing products which accelerates business development.
          </p>
          <button
            className={classes.homepage_header__button}
            onClick={() => discoverBtnHandler()}
          >
            DISCOVER MORE
          </button>
        </div>
      </Fade>
      <Fade right>
        <div className={classes.homepage_header__img}>
          <LazyLoadImage
            src={homepageHeaderImg}
            alt="product image"
            className={classes.homepage_header__imgClass}
          />
        </div>
      </Fade>
    </div>
  );
};

export default HomepageHeader;
