import React from "react";
import classes from "../../pages/SignupPage/Signup.module.css";

// Signup page button; custom

const Button = ({ type }) => {
  const color = type.disabled ? "grey" : type.color;
  return (
    <button
      style={{ backgroundColor: `${color}` }}
      className={classes.btn}
      onClick={type.onClick}
      disabled={type.disabled}
    >
      <p>
        <div className={classes.icon}>{type.icon ? type.icon : ""}</div> &nbsp;
        {type.description}{" "}
      </p>
    </button>
  );
};

export default Button;
