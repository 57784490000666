import React from "react";

import { TiSocialTwitter } from "react-icons/ti";
import { AiFillLinkedin, AiOutlineGoogle } from "react-icons/ai";

import logo from "../../images/logo3.jpg";

import classes from "./Footer.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Footer() {
  // For dynamic year
  const d = new Date();
  const curYear = d.getFullYear();

  return (
    <div className={classes.footer_container}>
      <div className={classes.main_logo_container}>
        <LazyLoadImage src={logo} alt="" className={classes.logo_height} />
        <div className={classes.footer_logoSubheading}>
          Follow us for more details and enquiries
        </div>

        <span className={classes.copyright}>
          &#169; {curYear} All rights reserved
        </span>
      </div>
      <div className={classes.leftSide}>
        <div className={classes.leftSide_first}>
          <div className="reachus_container">
            <div className={classes.reachus_header}>REACH US</div>
            <div className={classes.reachus_mail}>
              aircorpofficial@gmail.com
            </div>
          </div>
          <div className="social_connect">
            <a
              href="https://www.linkedin.com/company/autogenbot"
              target="_blank"
            >
              <AiFillLinkedin className={classes.social_icon} />
            </a>
            <a href="https://twitter.com/Autogenbot" target="_blank">
              <TiSocialTwitter className={classes.social_icon} />
            </a>
            <a href="mailto:aircorpofficial@gmail.com" target="_blank">
              <AiOutlineGoogle className={classes.social_icon} />
            </a>
          </div>
        </div>

        <div className={classes.quick_links_container}>
          <div className={classes.quick_links_header}>QUICK LINKS</div>

          <ul className={classes.quick_links_list}>
            <a href="/">
              <li>Home</li>
            </a>
            <a href="/agri-robots">
              <li>Agri Robotics</li>
            </a>
            <a href="/about">
              <li>About Us</li>
            </a>
            <a href="/logistic-robots">
              <li>Logistics Robots</li>
            </a>

            <a href="/contact">
              <li>Contact Us</li>
            </a>

            <a href="/service-robots">
              <li>Service Robotics</li>
            </a>
          </ul>
        </div>
        <p className={classes.mobile_copyright}>
          {" "}
          &#169; {curYear} All rights reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
