import React from "react";
import "../../App.css";
import "../../components/HeroSection.css";

const CommingSoon = ({ item }) => {
  return (
    <div className="hero-container">
      {/* <video src='/videos/video-2.mp4' autoPlay loop muted /> */}
      <strong>
        <h1>{item} Robots</h1>
      </strong>
      <p>Coming Soon</p>
      <p>Contact us for more details</p>
    </div>
  );
};

export default CommingSoon;
