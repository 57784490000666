import React, { useEffect } from "react";
import About from "../LegacyCode/About";
import HomepageTeam from "../Homepage/TeamComponent/HomepageTeam";
import MetaDecorator from "../../util/MetaDecorator";

const AboutusPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="layout">
      <MetaDecorator
        title="Autogenbots | AirBots - About"
        description=" A STARTUP THAT SEEKS TO PROVIDE ROBOTICS MOBILITY SOLUTIONS TO ACCELERATE
      BUSINESS DEVELOPMENT THROUGH ROBOTIC LABOUR FORCE"
      />
      <About />
      <HomepageTeam />
    </div>
  );
};

export default AboutusPage;
