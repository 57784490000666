import React from "react";
import ReactDom from "react-dom";
import { NavLink } from "react-router-dom";
export const Modal = ({ closeModal }) => {
  // close the modal when clicking outside the modal.
  //render the modal JSX in the portal div.
  return ReactDom.createPortal(
    <div className="container">
      <ul className="modal">
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "nav1-links1 active-nav" : "nav1-links1"
            }
            onClick={() => closeModal()}
          >
            HOME
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/agri-robots"
            className={({ isActive }) =>
              isActive ? "nav1-links1 active-nav" : "nav1-links1"
            }
            onClick={() => closeModal()}
          >
            AGRI ROBOTS
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/logistic-robots"
            className={({ isActive }) =>
              isActive ? "nav1-links1 active-nav" : "nav1-links1"
            }
            onClick={() => closeModal()}
          >
            LOGISTICS ROBOTS
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/service-robots"
            className={({ isActive }) =>
              isActive ? "nav1-links1 active-nav" : "nav1-links1"
            }
            onClick={() => closeModal()}
          >
            SERVICE ROBOTS
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? "nav1-links1 active-nav" : "nav1-links1"
            }
            onClick={() => closeModal()}
          >
            ABOUT US
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? "nav1-links1 active-nav" : "nav1-links1"
            }
            onClick={() => closeModal()}
          >
            CONTACT US
          </NavLink>
        </li>
      </ul>
    </div>,
    document.getElementById("portal")
  );
};
