import React from "react";
import web from "../../images/logo3.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
//import { NavLink } from "react-router-dom";

const About = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div
            className=" mx-auto"
            style={{
              backgroundColor: "black",
              marginBottom: "-80px",
              marginTop: "-60px",
            }}
          >
            <div className="row g-0   rounded overflow-hidden flex-md-row  shadow-sm h-md-250 position-relative">
              <section id="header" className="d-flex align-items-center">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-10 mx-auto">
                      <div className="row ">
                        <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                          <h1>
                            <strong></strong>{" "}
                            <strong className="brand-name">AIRbots</strong>
                          </h1>
                          <h2 className="my-3">
                            An emerging startup that seeks to provide mobility
                            solutions by providing products which accelerates
                            business development
                          </h2>
                          <div className="mt-3" style={{ padding: "5%" }}>
                            {/* <Link to='/' className="btn-get-started">
                          click
                        <Link> */}
                          </div>
                        </div>

                        <div className="col-lg-6 order1 order-lg-2 header-mg">
                          <LazyLoadImage
                            src={web}
                            className="img-fluid animated "
                            alt="img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
