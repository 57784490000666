import React from "react";
import "./slick.css";
import "./slick-theme.css";
import vipinImg from "../../../images/vipinkvImg.jpeg";
import vigneshImg from "../../../images/vigneshImg.jpg";
import ameenImg from "../../../images/ameennoushadImg.jpg";

import { AiFillLinkedin, AiFillGithub } from "react-icons/ai";

import classes from "../Homepage.module.css";
import Slider from "react-slick";
import SliderItem from "./SliderItem";

const TEAM_MEMBER_DETAILS = [
  {
    id: 1,
    imgUrl: vipinImg,
    name: "VIPIN K V",
    title: "CEO",
    socials: [
      {
        icon: (
          <AiFillLinkedin
            className={classes.social_link_slider}
            alt="linkedin icon"
          />
        ),
        redirectUrl: "https://www.linkedin.com/in/vipin-k-v-9245b9151/",
      },
      {
        icon: (
          <AiFillGithub
            className={classes.social_link_slider}
            alt="github icon"
          />
        ),
        redirectUrl: "https://github.com/thinkervipin",
      },
    ],
    // description:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus venenatis pellentesque vulputate. Sed non urna turpis. Aenean ultricies elit in ex varius, iaculis pharetra odio condimentum. Duis dictum arcu vel orci ",
  },
  {
    id: 2,
    imgUrl: vigneshImg,
    name: "VIGNESH MURUGAN",
    title: "HEAD OF OPERATIONS",
    socials: [
      {
        icon: (
          <AiFillLinkedin
            className={classes.social_link_slider}
            alt="linkedin icon"
          />
        ),
        redirectUrl: "https://www.linkedin.com/in/vignesh-murugan-582471259/",
      },
    ],
    // description:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus venenatis pellentesque vulputate. Sed non urna turpis. Aenean ultricies elit in ex varius, iaculis phase ",
  },
  {
    id: 1,
    imgUrl: ameenImg,
    name: "AMEEN NOUSHAD",
    title: "BUSINESS HEAD & WEB DEVELOPER",
    socials: [
      {
        icon: (
          <AiFillLinkedin
            className={classes.social_link_slider}
            alt="linkedin icon"
          />
        ),
        redirectUrl: "https://www.linkedin.com/in/ameen-noushad-888b79242/",
      },
      {
        icon: (
          <AiFillGithub
            className={classes.social_link_slider}
            alt="github icon"
          />
        ),
        redirectUrl: "https://github.com/Ameen-Here",
      },
    ],
    // description:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus venenatis pellentesque vulputate. Sed non urna turpis. Aenean ultricies elit in ex varius, iaculis pharetra odio condimentum.  ",
  },
];

const CarouselTeam = () => {
  const team_members = TEAM_MEMBER_DETAILS.map((detail) => (
    <SliderItem detail={detail} />
  ));
  const settings = {
    slidesToShow: 3,
    // For Future Changes
    // dots: true,
    // infinite: true,
    // speed: 500,
    // slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2500,
    // cssEase: "linear",
  };
  return (
    <div className={classes.slider}>
      <Slider {...settings}>{team_members}</Slider>
    </div>
  );
};

export default CarouselTeam;
