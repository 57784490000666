import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Navbar.css";
import logo from "../images/logo3.jpg";
import { GiHamburgerMenu } from "react-icons/gi";
import { Modal } from "./Modal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { NAVBAR_ITEMS } from "./NavbarData";

function Navbar() {
  const [isHamburgerOn, setIsHamburgerOn] = useState(false); // Dynamically setting hamburger using state
  const [showModal, setShowModal] = useState(false);

  // If screen width is less than 960, hamburger is added in navbar

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const hamburgerClickHandler = () => {
    showModal === true ? closeModal() : openModal();
  };

  // Setting dynamic navbar values
  const [currentNavbarItems, setCurrentNavbarItems] = useState();
  let tempNavbarItems;
  const signUpNavbarItems = NAVBAR_ITEMS.filter((item) => item.isSignupPage);
  const loginNavbarItems = NAVBAR_ITEMS.filter((item) => item.isLoginPage);
  const homeNavbarItems = NAVBAR_ITEMS.filter((item) => item.isHomepage);

  useEffect(() => {
    window.innerWidth <= 960 ? setIsHamburgerOn(true) : setIsHamburgerOn(false);
  }, []);

  const location = useLocation(); // For activating useEffect whenever url changes

  useEffect(() => {
    // Getting value after last "/" and if it is sign up/ login custom make their navbar values using filter
    tempNavbarItems = window.location.href
      .slice(window.location.href.lastIndexOf("/"))
      .startsWith("/signup")
      ? signUpNavbarItems
      : window.location.href
          .slice(window.location.href.lastIndexOf("/"))
          .startsWith("/login")
      ? loginNavbarItems
      : homeNavbarItems;

    setCurrentNavbarItems(
      tempNavbarItems.map((item) => (
        <li className="nav1-item1">
          <NavLink
            to={item.to}
            className={({ isActive }) =>
              isActive ? "nav1-links1 active-nav" : "nav1-links1"
            }
          >
            {item.title}
          </NavLink>
        </li>
      ))
    );
  }, [location.pathname]);

  return (
    <>
      <nav className="navbar1">
        <NavLink to="/" className="navbar-logo1">
          <LazyLoadImage
            src={logo}
            alt="Logo Leading to homepage"
            className="logo-height"
          />
        </NavLink>

        {!isHamburgerOn && (
          <ul className="navbar1-container1">{currentNavbarItems}</ul>
        )}

        {showModal ? <Modal closeModal={hamburgerClickHandler} /> : null}
        {isHamburgerOn && (
          <GiHamburgerMenu
            onClick={hamburgerClickHandler}
            className="hamburger_menu"
          />
        )}
      </nav>
    </>
  );
}

export default Navbar;
