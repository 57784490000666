import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./Signup.module.css";

const FontAwesome = ({ validValue, value }) => {
  return (
    <>
      {" "}
      <FontAwesomeIcon
        icon={faCheck}
        className={validValue ? `${classes.valid}` : `${classes.hide}`}
      />
      <FontAwesomeIcon
        icon={faTimes}
        className={
          validValue || !value ? `${classes.hide}` : `${classes.invalid}`
        }
      />
    </>
  );
};

export default FontAwesome;
