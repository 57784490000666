import React from "react";
import classes from "../../pages/SignupPage/Signup.module.css";

const Input = ({
  matchVal,
  changeSetVal,
  placeholderText,
  validVal,
  curRef,
}) => {
  return (
    <>
      <input
        className={classes.signup_input}
        placeholder={placeholderText}
        type="text"
        value={matchVal}
        autoComplete="off"
        required
        onChange={(e) => changeSetVal(e.target.value)}
        aria-invalid={validVal ? "false" : "true"}
        ref={curRef}
      />
    </>
  );
};

export default Input;
