import React, { useEffect, useRef } from "react";
import Button from "../../components/SignupLogin/Button";
import classes from "../SignupPage/Signup.module.css";
import { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FontAwesome from "../SignupPage/FontAwesome";
import Input from "../../components/SignupLogin/Input";
import { PWD_REGEX, EMAIL_REGEX } from "../SignupPage/REGEX_VALUES";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);

  const [btnDisable, setBtnDisable] = useState(true);

  //   Checking if all inputs are valid
  const validInpCheck = () => {
    if (validEmail && validPwd) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  };

  //   Validating email when the new input is given
  useEffect(() => {
    const result = EMAIL_REGEX.test(email);

    setValidEmail(result);
  }, [email]);

  //   Validating password when the new input is given
  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
  }, [pwd]);

  useEffect(() => {
    validInpCheck();
  }, [validEmail, validPwd]);

  //   Form Submission Handler

  const formSubmit = (e) => {
    e.preventDefault();
    // Rechecking if all the values are added; to prevent jHack
    if (!validEmail && !validPwd) {
      toast.error("Fill all the required fields");
      return;
    }
    console.log({
      email,
      password: pwd,
    });
    toast.success("Successfully logged in");
  };
  return (
    <form onSubmit={formSubmit}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <div className={classes.input_container}>
        <Input
          matchVal={email}
          changeSetVal={(val) => setEmail(val)}
          placeholderText="Email Address"
          validVal={validEmail}
        />
        <FontAwesome validValue={validEmail} value={email} />
      </div>
      <div className={classes.input_container}>
        <Input
          matchVal={pwd}
          changeSetVal={(val) => setPwd(val)}
          placeholderText="Password"
          validVal={validPwd}
        />
        <FontAwesome validValue={validPwd} value={pwd} />
      </div>
      <p className={classes.pwdInfo}>
        *Password must be 6 length or more of alphanumeric characters
      </p>
      <Button
        type={{
          description: "Login with Email",
          color: "#019BFE",
          disabled: btnDisable,
        }}
      />
    </form>
  );
};

export default LoginForm;
