import React from "react";
import "./Service.css";
import rgreen from "../../images/robog.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ScienceIcon from "@mui/icons-material/Science";

const Service = () => {
  return (
    <>
      <div className="my-5">
        <h1 className="display-4 fw-bold lh-1 text-center ">Product</h1>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                <h1
                  className="display-4 fw-bold lh-1"
                  style={{ padding: "2%", align: "center" }}
                >
                  Feature
                </h1>
                <div className="service" style={{ padding: "5%" }}>
                  <p>
                    <ElectricCarIcon fontSize="large" color="success" /> Fully
                    Electric vehicle
                  </p>
                  <p>
                    <FitnessCenterIcon fontSize="large" color="success" />{" "}
                    Payload of more than 400kg*
                  </p>
                  <p className="lead">
                    <SportsEsportsIcon fontSize="large" color="success" /> Easy
                    to Control{" "}
                  </p>
                  <p className="lead">
                    <BatteryChargingFullIcon fontSize="large" color="success" />{" "}
                    Run time of 5 hours*
                  </p>
                  <p className="lead">
                    <CurrencyRupeeIcon fontSize="large" color="success" />{" "}
                    Economical solution
                  </p>
                  <p className="lead">
                    <ScienceIcon fontSize="large" color="success" />{" "}
                    Customisation according to client
                  </p>
                  <p1>
                    *These setting can be adjusted according to customer needs
                  </p1>
                </div>
              </div>

              <div
                className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column"
                style={{ padding: "5%" }}
              >
                <LazyLoadImage
                  src={rgreen}
                  className="img-fluid animated "
                  alt="img"
                  style={{ paddingTop: "5%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
