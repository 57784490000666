import React from "react";
import classes from "../Homepage.module.css";
import Fade from "react-reveal/Fade";
import FeatureDetails from "./FeatureDetails";
import economicIcon from "../../../images/economicalIcon.png";
import swappableBatteryIcon from "../../../images/swappableBatteryIcon.jpeg";
import customizableIcon from "../../../images/customizableIcon.png";
import madeInIndiaIcon from "../../../images/madeInIndiaIcon.png";

const FEATURE_DATAS = [
  {
    id: 1,
    icon: economicIcon,
    title: "ECONOMICAL ",
    description: "Our robots strive to make an ROI within 12 - 18 months",
  },
  {
    id: 2,
    icon: swappableBatteryIcon,
    title: "SWAPPABLE BATTERY",
    description: "Our swappable system enables 24/7 usage",
  },
  {
    id: 3,
    icon: customizableIcon,
    title: "TAILORED SOLUTION",
    description: "Our robots are designed according to your needs",
  },
  {
    id: 4,
    icon: madeInIndiaIcon,
    title: "MADE IN INDIA",
    description:
      "All our products are manufactured and developed in India for global market",
  },
];

const HomepageFeature = () => {
  const featureDatas = FEATURE_DATAS.map((feature) => (
    <FeatureDetails feature={feature} />
  ));
  return (
    <div className={classes.feature_container}>
      <Fade right>
        <h3 align="center" className={classes.product_header}>
          FEATURE
        </h3>
      </Fade>

      <div className={classes.homepage_feature}>{featureDatas}</div>
    </div>
  );
};

export default HomepageFeature;
