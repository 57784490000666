export const NAVBAR_ITEMS = [
  {
    title: "HOME",
    isLoginPage: true,
    isSignupPage: true,
    isHomepage: true,
    to: "/",
  },
  {
    title: "AGRI ROBOTS",
    isLoginPage: false,
    isSignupPage: false,
    isHomepage: true,
    to: "/agri-robots",
  },
  {
    title: "LOGISTICS ROBOTS",
    isLoginPage: false,
    isSignupPage: false,
    isHomepage: true,
    to: "/logistic-robots",
  },
  {
    title: "SERVICE ROBOTS",
    isLoginPage: false,
    isSignupPage: false,
    isHomepage: true,
    to: "/service-robots",
  },
  {
    title: "ABOUT US",
    isLoginPage: true,
    isSignupPage: true,
    isHomepage: true,
    to: "/about",
  },
  {
    title: "CONTACT US",
    isLoginPage: true,
    isSignupPage: true,
    isHomepage: true,
    to: "/contact",
  },
  {
    title: "LOGIN",
    isLoginPage: false,
    isSignupPage: true,
    isHomepage: false,
    to: "/login",
  },
  {
    title: "SIGN UP",
    isLoginPage: true,
    isSignupPage: false,
    isHomepage: false,
    to: "/signup",
  },
];
