import React from "react";
import classes from "../Homepage.module.css";
import Fade from "react-reveal/Fade";
import CarouselTeam from "./CarouselTeam";

const HomepageTeam = () => {
  return (
    <div className={classes.homepage_team__container}>
      <Fade right>
        <h3 align="center" className={classes.product_header}>
          LEADERSHIP
        </h3>
      </Fade>
      <CarouselTeam />
    </div>
  );
};

export default HomepageTeam;
