import React from "react";
import classes from "../Homepage.module.css";
import { useNavigate } from "react-router-dom";

import Fade from "react-reveal/Fade";

const ProductDetail = ({ product }) => {
  const navigate = useNavigate();
  const leftTrue = product.id % 2 === 0 ? true : false;
  const descriptionList = product.descriptions.map((description, index) => (
    <Fade left={leftTrue} right={!leftTrue} duration={(index + 0.5) * 400}>
      <li>{description}</li>
    </Fade>
  ));

  const btnHandler = () => {
    // `NAVIGATE TO REDIRECT URL ${product.redirectLink}`
    navigate(`${product.redirectLink}`);
  };

  // Dynamic class for header and btn :- To give different css

  let headerClassname = `${classes.highlight_container}`;
  let btnClassname = `${classes.product_description__details_btn}`;

  product.color === "blue"
    ? (headerClassname += ` ${classes.highlight_container__blue}`)
    : product.color === "red"
    ? (headerClassname += ` ${classes.highlight_container__red}`)
    : (headerClassname += ` ${classes.highlight_container__green}`);

  product.color === "blue"
    ? (btnClassname += ` ${classes.product_description__details_btn__blue}`)
    : product.color === "red"
    ? (btnClassname += ` ${classes.product_description__details_btn__red}`)
    : (btnClassname += ` ${classes.product_description__details_btn__green}`);

  return (
    <div className={classes.product_description}>
      <Fade left={leftTrue} right={!leftTrue}>
        <img
          src={product.img}
          alt={`${product.title} image`}
          className={classes.product_description__img}
        />
      </Fade>

      <div className={classes.product_description__details}>
        <Fade left={leftTrue} right={!leftTrue} duration={1500}>
          <h5 className={classes.product_description__details_header}>
            <span className={headerClassname}>{product.title}</span> ROBOTS
          </h5>
        </Fade>

        <ul className={classes.product_description__details_list}>
          {descriptionList}
        </ul>
        <Fade left={leftTrue} right={!leftTrue} duration={1700}>
          <button className={btnClassname} onClick={btnHandler}>
            MORE DETAILS
          </button>
        </Fade>
      </div>
    </div>
  );
};

export default ProductDetail;
